
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import { EditDataPriceModalDto } from "@/api/interfaces/data-price/common";
import { ICountriesDataPrice } from "@/store/interfaces/data-price/IDataPrice";

const initialData = () => ({
  id: -1,
  amount: 0,
  validation: false,
  parentTouched: false,
  dataPrice: null
});

export default defineComponent({
  name: "EditDataPriceDialog",
  components: {
    AppButton,
    AppInput,
    Dialog,
    AppInputsValidationProvider
  },

  inheritAttrs: false,

  props: {
    editData: {
      type: Object as PropType<ICountriesDataPrice | null>,
      required: true
    },
    disabled: { type: Boolean, default: true },
    opened: { type: Boolean, default: false },
    title: { type: String, default: "Edit data price" },
    endCreate: {
      type: Function,
      required: true
    },
    loading: { type: Boolean, default: false }
  },
  emits: {
    hide: null
  },

  data() {
    return initialData();
  },
  watch: {
    editData: {
      handler(data: any) {
        if (!data) {
          Object.assign(this.$data, initialData());
        } else {
          Object.assign(this.$data, data);
          this.amount = data.dataPrice?.amount || 0;
        }
      },
      immediate: true
    }
  },

  methods: {
    async submit(): Promise<void> {
      const { id, amount, dataPrice } = this;
      let data: EditDataPriceModalDto = {
        amount: +amount,
        countryId: id
      };
      if ((dataPrice as any)?.id) {
        data = Object.assign(data, { id: (dataPrice as any).id });
      }

      const success = await this.endCreate(data);
      if (success) {
        Object.assign(this.$data, initialData());
      }
    },

    onChangeErrorState(error: boolean) {
      this.validation = error;
    },

    hide() {
      this.$emit("hide");
    }
  }
});
