import { IBaseQuery } from "@/api/interfaces/IApi";
import { useStore } from "vuex";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";

export type useBaseAppLayoutOptions = {
  viewConfiguration: IBaseQuery;
  filter: any;
};

export function useDataPrice(options: useBaseAppLayoutOptions) {
  const { viewConfiguration, filter } = options;
  const store = useStore();
  function changeViewConfigurationHandler({
    page,
    perPage
  }: ITableViewConfiguration): void {
    if (
      page === viewConfiguration.page &&
      perPage !== viewConfiguration.perPage
    ) {
      viewConfiguration.page = 1;
    } else {
      viewConfiguration.page = page;
    }

    viewConfiguration.perPage = perPage;
  }
  async function requestDataPriceHandler(
    showLoading = true,
    fullFilter?: any
  ): Promise<void> {
    showLoading && (await store.dispatch("setAppLoading", true));
    const { success, message } = await store.dispatch("fetchDataPriceList", {
      query: viewConfiguration,
      filter: fullFilter || filter
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }

  async function editDataPrice(data: any) {
    const { success, message } = await store.dispatch("editDataPrice", {
      data: { ...data }
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    return success;
  }

  return {
    changeViewConfigurationHandler,
    requestDataPriceHandler,
    editDataPrice
  };
}
